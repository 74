<template>
  <div>
    <app-base />
  </div>
</template>

<script>
import AppBase from "@/Druware.Web.Shared/components/AppBase";

export default {
  components: {
    AppBase
  }
};
</script>

<style lang="scss" scoped></style>
